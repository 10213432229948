'use client';

import type { UIComponentProps } from '@wolfejs/ui/types/UI';
import { type LucideProps } from 'lucide-react';
import dynamic from 'next/dynamic';

export const UserIcon = dynamic<UIComponentProps>(() => import('./flowbite/User.svg'));

export const UserFilledIcon = dynamic<UIComponentProps>(() => import('./flowbite/Filled/User.svg'));
// export const MoonIcon = dynamic<UIComponentProps>(() => import('./flowbite/Filled/Moon.svg'));
// export const SunIcon = dynamic<UIComponentProps>(() => import('./flowbite/Sun.svg'));
export const CollectionIcon = dynamic<UIComponentProps>(() => import('./flowbite/Collection.svg'));
export const ReceiptDollarIcon = dynamic<UIComponentProps>(() => import('./flowbite/ReceiptDollar.svg'));
export const ChartSquareBarIcon = dynamic<UIComponentProps>(() => import('./flowbite/ChartSquareBar.svg'));
export const ArchiveIcon = dynamic<UIComponentProps>(() => import('./flowbite/Archive.svg'));
export const AngleLeftIcon = dynamic<UIComponentProps>(() => import('./flowbite/AngleLeft.svg'));
export const AngleRightIcon = dynamic<UIComponentProps>(() => import('./flowbite/AngleRight.svg'));
export const ReefMarkettingIcon = dynamic<UIComponentProps>(() => import('./ms/ReefMarketting.svg'));
export const WolfeMerchantSolutionsIcon = dynamic<UIComponentProps>(() => import('./ms/wolfe-merchant-solutions.svg'));
export const MSCartIcon = dynamic<UIComponentProps>(() => import('./ms/cart.svg'));
export const C2CIcon = dynamic<UIComponentProps>(() => import('./ms/c2c.svg'));
export const CreditCardIcon = dynamic<UIComponentProps>(() => import('./ms/credit-card.svg'));
export const CloudIcon = dynamic<UIComponentProps>(() => import('./ms/cloud.svg'));
export const BlocksIcon = dynamic<UIComponentProps>(() => import('./ms/settings.svg'));
export const UsFlag = dynamic<UIComponentProps>(() => import('./ms/us.svg'));
export const CheckCircleIcon = dynamic<UIComponentProps>(() => import('./ms/check-circle-icon.svg'));
export const XCircleIcon = dynamic<UIComponentProps>(() => import('./ms/x-circle-icon.svg'));
export const DotsVerticalIcon = dynamic<UIComponentProps>(() => import('./ms/dots-vertical.svg'));
export const Cart = dynamic<UIComponentProps>(() => import('./flowbite/Filled/Cart.svg'));
export const EmptyCartBackground = dynamic<UIComponentProps>(() => import('./ms/empty-cart-background.svg'));
export const Cash = dynamic<UIComponentProps>(() => import('./flowbite/Filled/Cash.svg'));
export const ChevronRight = dynamic<UIComponentProps>(() => import('./flowbite/ChevronRight.svg'));
export const ArrowRight = dynamic<UIComponentProps>(() => import('./flowbite/arrow-right.svg'));
export const ArrowLeft = dynamic<UIComponentProps>(() => import('./flowbite/arrow-left.svg'));
export const PrinterIcon = dynamic<UIComponentProps>(() => import('./flowbite/Filled/Printer.svg'));
export const FileInvoice = dynamic<UIComponentProps>(() => import('./flowbite/file-invoice.svg'));
export const Eye = dynamic<UIComponentProps>(() => import('./flowbite/eye.svg'));
export const FileLines = dynamic<UIComponentProps>(() => import('./flowbite/file-lines.svg'));
export const Filter = dynamic<UIComponentProps>(() => import('./flowbite/filter.svg'));
export const Undo = dynamic<UIComponentProps>(() => import('./flowbite/undo.svg'));
export const Calendar = dynamic<UIComponentProps>(() => import('./flowbite/calendar.svg'));
export const CalendarMonth = dynamic<UIComponentProps>(() => import('./flowbite/Filled/CalendarMonth.svg'));
export const CalendarWeek = dynamic<UIComponentProps>(() => import('./flowbite/Filled/CalendarWeek.svg'));
export const Info = dynamic<UIComponentProps>(() => import('./flowbite/info.svg'));
export const Receipt = dynamic<UIComponentProps>(() => import('./flowbite/receipt.svg'));
export const Bank = dynamic<UIComponentProps>(() => import('./reafCustom/Bank.svg'));
export const Wirexfer = dynamic<UIComponentProps>(() => import('./reafCustom/Wirexfer.svg'));
export const CircleCheck = dynamic<UIComponentProps>(() => import('./flowbite/Filled/CircleCheck.svg'));
export const CreditCardFilled = dynamic<UIComponentProps>(() => import('./flowbite/Filled/CreditCard.svg'));
export const PiggyBank = dynamic<UIComponentProps>(() => import('./ms/piggybank.svg'));
export const CardWithAngle = dynamic<UIComponentProps>(() => import('./ms/card.svg'));
export const History = dynamic<UIComponentProps>(() => import('./ms/history.svg'));
export const Person = dynamic<UIComponentProps>(() => import('./ms/person.svg'));
export const Reporting = dynamic<UIComponentProps>(() => import('./ms/reporting.svg'));
export const ChevronDown = dynamic<UIComponentProps>(() => import('./flowbite/chevron-down.svg'));
export const ChevronUp = dynamic<UIComponentProps>(() => import('./flowbite/chevron-up.svg'));
export const ChevronDoubleLeft = dynamic<UIComponentProps>(() => import('./flowbite/chevron-double-left.svg'));
export const CloseX = dynamic<UIComponentProps>(() => import('./flowbite/close-x.svg'));
export const Template = dynamic<UIComponentProps>(() => import('./flowbite/Template.svg'));
export const ReceiptDollar = dynamic<UIComponentProps>(() => import('./flowbite/receipt-dollar.svg'));
export const Reports = dynamic<UIComponentProps>(() => import('./flowbite/document-report.svg'));
export const Cog = dynamic<UIComponentProps>(() => import('./flowbite/cog-round.svg'));
export const Support = dynamic<UIComponentProps>(() => import('./flowbite/Support.svg'));
export const Collection = dynamic<UIComponentProps>(() => import('./ms/collection.svg'));
export const UserGroup = dynamic<UIComponentProps>(() => import('./ms/user-group.svg'));
export const FileImport = dynamic<UIComponentProps>(() => import('./flowbite/file-import.svg'));
export const Download = dynamic<UIComponentProps>(() => import('./flowbite/download.svg'));

export const ImageIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/image').then(mod => mod.default)
);

export const KeyIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/key').then(mod => mod.default)
);

export const EmailIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/mail').then(mod => mod.default)
);

export const MerchantIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/building-2').then(mod => mod.default)
);

export const BrandIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/boxes').then(mod => mod.default)
);

export const PortalIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/cloud').then(mod => mod.default)
);

export const BuyerIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/shopping-basket').then(mod => mod.default)
);

export const UserIconAlt = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/user').then(mod => mod.default)
);

export const UsersIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/users').then(mod => mod.default)
);

export const CogIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/cog').then(mod => mod.default)
);

export const LogoutIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/log-out').then(mod => mod.default)
);

export const MoonIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/moon').then(mod => mod.default)
);

export const SunIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/sun').then(mod => mod.default)
);

export const GripVerticalIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/grip-vertical').then(mod => mod.default)
);

export const InfoIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/info').then(mod => mod.default)
);
export const ErrorIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/x-circle').then(mod => mod.default)
);
export const WarningIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/alert-triangle').then(mod => mod.default)
);
export const SuccessIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/check-circle-2').then(mod => mod.default)
);
export const CheckIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/check').then(mod => mod.default)
);
export const UploadIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/upload').then(mod => mod.default)
);
// @ts-ignore
export const MenuIcon = dynamic<LucideProps>(() => import('lucide-react/dist/esm/icons/menu').then(mod => mod.default));
// @ts-ignore
export const BellIcon = dynamic<LucideProps>(() => import('lucide-react/dist/esm/icons/bell').then(mod => mod.default));
export const TemplateIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/layout-template').then(mod => mod.default)
);
export const SettingsIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/settings').then(mod => mod.default)
);
export const HelpIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/life-buoy').then(mod => mod.default)
);
export const LeftArrowIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/arrow-left').then(mod => mod.default)
);
export const RightArrowIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/arrow-right').then(mod => mod.default)
);
export const CartIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/shopping-cart').then(mod => mod.default)
);
// @ts-ignore
export const HomeIcon = dynamic<LucideProps>(() => import('lucide-react/dist/esm/icons/home').then(mod => mod.default));
export const UpIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/chevron-up').then(mod => mod.default)
);
export const DownIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/chevron-down').then(mod => mod.default)
);
// @ts-ignore
export const PlusIcon = dynamic<LucideProps>(() => import('lucide-react/dist/esm/icons/plus').then(mod => mod.default));
export const DeleteIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/trash-2').then(mod => mod.default)
);
export const XIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/x').then(mod => mod.default)
);
export const Trash2Icon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/trash-2').then(mod => mod.default)
);
// @ts-ignore
export const EditIcon = dynamic<LucideProps>(() => import('lucide-react/dist/esm/icons/edit').then(mod => mod.default));
export const PencilIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/pencil').then(mod => mod.default)
);

export const CloseIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/x-circle').then(mod => mod.default)
);
export const EyeIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/eye').then(mod => mod.default)
);
export const PlayIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/play').then(mod => mod.default)
);
export const HeartIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/heart').then(mod => mod.default)
);
export const ArrowDownUpIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/arrow-down-up').then(mod => mod.default)
);
export const SearchIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/search').then(mod => mod.default)
);

export const FilterIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/filter').then(mod => mod.default)
);

export const RedoIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/redo-2').then(mod => mod.default)
);

export const CalendarDaysIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/calendar-days').then(mod => mod.default)
);

export const MoreHorizontalIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/more-horizontal').then(mod => mod.default)
);

export const FileSpreadsheetIcon = dynamic<LucideProps>(() =>
  // @ts-ignore
  import('lucide-react/dist/esm/icons/file-spreadsheet').then(mod => mod.default)
);
