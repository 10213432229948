import React from 'react';

export type useFocusStateProps<ElementType> = {
  onBlur?: (ev: React.FocusEvent<ElementType>) => void;
  onFocus?: (ev: React.FocusEvent<ElementType>) => void;
};

/**
 * Track the focus state of a component
 */
export function useFocusState<ElementType = HTMLElement>({ onFocus, onBlur }: useFocusStateProps<ElementType>) {
  const [focused, setFocused] = React.useState<boolean>(false);

  const handleFocus = React.useCallback(
    (e: React.FocusEvent<ElementType>) => {
      setFocused(true);
      onFocus?.(e);
    },
    [onFocus]
  );

  const handleBlur = React.useCallback(
    (e: React.FocusEvent<ElementType>) => {
      setFocused(false);
      onBlur?.(e);
    },
    [onBlur]
  );

  return { focused, setFocused, handleFocus, handleBlur };
}
