'use client';

import { Slot } from '@radix-ui/react-slot';
import React from 'react';
import useMountedState from 'react-use/esm/useMountedState';
import type { UIComponentProps } from '../types/UI';
import { cn } from '../utils/classnames';

export interface ThemeContextState {
  theme?: string;
  setTheme?: (path: string) => void;
  themeMode?: string;
  setThemeMode?: (path: string) => void;
}

export const ThemeContext = React.createContext<ThemeContextState | null>(null);

export const ThemeProvider = ({ children, value }: UIComponentProps & { value: ThemeContextState }) => {
  const isMounted = useMountedState();

  return (
    <ThemeContext.Provider value={value}>
      <Slot className={cn(`ui-slot theme-${value.theme}`, { [`${value.themeMode}`]: isMounted() })}>{children}</Slot>
    </ThemeContext.Provider>
  );
};
