import type { Placement as FloatingUIPopoverPlacement } from '@floating-ui/dom';
import type { RecursiveKeyValuePair as RecursiveKeyValuePairTW } from 'tailwindcss/types/config';

// eslint-disable-next-line
export type RecursiveKeyValuePair<K extends keyof any = string, V = string> = RecursiveKeyValuePairTW<K, V>;

export type UIFCStaticProps = {
  colors: string[];
  variants: string[];
  shapes: string[];
  sizes: string[];
};

export type UIFC<T> = React.FC<T> & UIFCStaticProps;

export type UIComponentProps<Element = HTMLDivElement> = {
  // ref?: React.ForwardedRef<Element>;

  /**
   * id attribute passed to internal html element
   */
  id?: string;

  /**
   * component name
   */
  cname?: string;

  /**
   * component classname
   */
  className?: string;
  /**
   * component style
   */
  style?: React.CSSProperties;
  /**
   * component children
   */
  children?: React.ReactNode;
};

export type SlotEntry<T> =
  | React.FC<T>
  | React.ForwardRefExoticComponent<T>
  | React.ForwardRefExoticComponentExtended<T>;

export type SlotProps = {
  className?: string;
  children?: React.ReactNode;
};

export type UIComponentSlots<T> = {
  slots?: Record<string, T>;
  slotProps?: Record<string, SlotProps>;
  classNames?: Record<string, string>;
};

export type UIThemableComponent<T = UIComponentProps, Element = HTMLDivElement> = UIComponentProps<Element> &
  UIThemableComponentProps &
  UIComponentSlots<SlotEntry<T>>;

export type UIThemableComponentProps = {
  /**
   * component contextual color
   */
  color?: UIColor;
  /**
   * component shape
   */
  shape?: UIShape;
  /**
   * component variant
   */
  variant?: UIVariant;
  /**
   * component size
   */
  size?: UISize;
  /**
   * Use gradient background
   */
  gradient?: boolean;
  /**
   * Use colored shadows
   */
  colorShadows?: boolean;
  /**
   * Elevation to control shadow size/strength
   */
  elevation?: number;
};

export type MouseEventProps<Element = HTMLElement> = {
  onClick?: (ev: React.MouseEvent<Element>) => void;
  onMouseUp?: (ev: React.MouseEvent<Element>) => void;
  onMouseDown?: (ev: React.MouseEvent<Element>) => void;
  onMouseOver?: (ev: React.MouseEvent<Element>) => void;
  onMouseOut?: (ev: React.MouseEvent<Element>) => void;
  onMouseEnter?: (ev: React.MouseEvent<Element>) => void;
  onMouseLeave?: (ev: React.MouseEvent<Element>) => void;
};

export type FocusProps = {
  onFocus?(ev: React.FocusEvent<HTMLElement>): void;
  onBlur?(ev: React.FocusEvent<HTMLElement>): void;
};

export enum UIColorContext {
  default = 'default',
  primary = 'primary',
  secondary = 'secondary',
  accent = 'accent',
}

export enum UIColorStates {
  info = 'info',
  error = 'error',
  warning = 'warning',
  success = 'success',
}

export type UIColor = UIColorContext | UIColorStates | string;
export const UIColors = { ...UIColorContext, ...UIColorStates };

export enum UIVariants {
  solid = 'solid',
  outline = 'outline',
  ghost = 'ghost',
  unstyled = 'unstyled',
}

export enum UIVariantsExtra {
  link = 'link',
  alternative = 'alternative',
}

export type UIVariant = UIVariants | string;

export enum UIShapes {
  rect = 'rect',
  rounded = 'rounded',
}

export enum UIShapesExtra {
  pill = 'pill',
  circle = 'circle',
}

export type UIShape = UIShapes | string;

export enum UISizes {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  '2xl' = '2xl',
}

export type UISize = UISizes | string;

export enum vAlignment {
  Top = 'top',
  Middle = 'middle',
  Bottom = 'bottom',
}

export enum hAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum Placement {
  Left = 'L',
  LeftTop = 'LT',
  LeftBottom = 'LB',
  Right = 'R',
  RightTop = 'RT',
  RightBottom = 'RB',
  Top = 'T',
  TopRight = 'TR',
  TopLeft = 'TL',
  Bottom = 'B',
  BottomLeft = 'BL',
  BottomRight = 'BR',
}

export enum LayoutTypes {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

export type HorVerLayout = LayoutTypes.horizontal | LayoutTypes.vertical;

export enum PopoverPlacementBase {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
}

export enum PopoverVariationPlacement {
  TopStart = 'top-start',
  TopEnd = 'top-end',
  BottomStart = 'bottom-start',
  BottomEnd = 'bottom-end',
  RightStart = 'right-start',
  RightEnd = 'right-end',
  LeftStart = 'left-start',
  LeftEnd = 'left-end',
}

export const PopoverPlacements = { ...PopoverPlacementBase, ...PopoverVariationPlacement };
export type PopoverPlacement = FloatingUIPopoverPlacement;

export enum UISizeUnits {
  Rem = 'rem',
  Px = 'px',
}
