import type { SlotEntry, UIComponentProps } from '../types/UI';
import { cn } from './classnames';

export function renderSlot<T extends Object = UIComponentProps>(
  Slot: SlotEntry<T>,
  slotName: string,
  props?: T,
  className?: string
) {
  if (!Slot) return null;
  return (
    // TODO: fix typings here
    // @ts-ignore
    <Slot {...props} className={cn(slotName, className, props?.className)} />
  );
}
