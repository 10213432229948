import React from 'react';

export type useAutoFocusProps = {
  autoFocus?: boolean;
  ref: React.MutableRefObject<HTMLElement | null>;
  onBlur?: (ev: React.FocusEvent<HTMLElement>) => void;
  onFocus?: (ev: React.FocusEvent<HTMLElement>) => void;
};

/**
 * Auto focus a child when the component mounts.
 */
export function useAutoFocus({ ref, autoFocus }: useAutoFocusProps) {
  const focusInput = React.useCallback(() => {
    ref.current?.focus();
  }, [ref]);

  React.useEffect(() => {
    if (autoFocus && ref.current) focusInput();
  }, [autoFocus, ref, focusInput]);
}
