'use client';

import React from 'react';
import type { RefCallBack } from 'react-hook-form';
// import type { RefCallBack } from 'react-hook-form';
import { useAutoFocus } from '../hooks/useAutoFocus';
import { useFocusState } from '../hooks/useFocusState';
import { useTheme } from '../hooks/useTheme';
import { UIStyling } from '../styles/UIStyling';
import type { UIComponentProps } from '../types/UI';
import { UIColorContext, UIShapes, UISizes, UIVariants, type UIThemableComponent } from '../types/UI';
import { cn } from '../utils/classnames';
import { renderSlot } from '../utils/slots';

export type InputProps = UIThemableComponent<UIComponentProps<HTMLInputElement>, HTMLInputElement> & {
  ref?: React.ForwardedRef<HTMLInputElement> | null | RefCallBack;

  // ----- input props
  type?: string; // input element type attribute
  name?: string; // input element name attribute
  autoComplete?: string; // input element autoComplete attribute
  placeholder?: string; // input element placeholder attribute
  maxLength?: number; // input element maxLength attribute
  disabled?: boolean; // input element disabled attribute
  min?: string | number; // input element min attribute
  max?: string | number; // input element max attribute

  // ----- Values
  value?: string | null; // controlled value
  initialValue?: string; // uncontrolled value
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onValueChange?: (value: string) => void;

  // ----- Options
  fullWidth?: boolean; // use full width mode
  autoFocus?: boolean; // auto focus the input field when the component mounts
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  helperText?: React.ReactNode | string;

  /**
   * TabIndex that is passed to the main DOM element.
   * Set this to -1 to exclude this element from the index
   */
  tabIndex?: number;

  /**
   * A function that can be passed to render out a custom input instead of the default one
   */
  customInput?: (customProps: unknown) => React.ReactElement;

  // ref?: React.MutableRefObject<HTMLInputElement>;..

  // ----- Callbacks
  onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (ev: React.FocusEvent<HTMLInputElement>) => void;
};

function InputComp(
  {
    className,

    // ----- Themable props defaults
    color = UIColorContext.default,
    variant = UIVariants.solid,
    shape = UIShapes.rounded,
    size = UISizes.md,
    gradient,
    elevation,

    // ----- Values
    value,
    initialValue,
    onChange,
    onValueChange,

    // ----- Options
    disabled,
    prepend,
    append,
    autoFocus,
    fullWidth,
    customInput,
    tabIndex,
    helperText,

    // ----- Slots
    slots = {
      prepend: ({ className, children }: UIComponentProps<HTMLInputElement>) => (
        <div className={cn('ui-input__prepend', className)}>{children}</div>
      ),
      append: ({ className, children }: UIComponentProps<HTMLInputElement>) => (
        <div className={cn('ui-input__append', className)}>{children}</div>
      ),
    },
    slotProps,
    classNames,

    // ----- callbacks
    onFocus,
    onBlur,
    ...otherProps
  }: InputProps,
  forwardedRef: React.ForwardedRef<HTMLInputElement>
) {
  const { theme } = useTheme();
  const themableProps = { variant, color, shape, size, theme, gradient, elevation };

  // support for uncontrolled component usage
  const [unControlledValue, setUnControlledValue] = React.useState<string>(initialValue || '');
  const controlled = initialValue === undefined;

  // remove component from tabindex when it is disabled
  const _tabIndex = React.useMemo(() => {
    if (disabled) return -1;
    return tabIndex;
  }, [disabled, tabIndex]);

  const ref = forwardedRef as React.MutableRefObject<HTMLInputElement>;

  // track focused state
  const { focused, handleFocus, handleBlur } = useFocusState<HTMLInputElement>({ onFocus, onBlur });

  // a ref to the inner html element
  // const inputRef = React.useRef<HTMLInputElement | null>(null);

  // auto focus the input field if autoFocus is true
  useAutoFocus({ ref, autoFocus });

  /**
   * Focus on the input field when the user clicks outside of it but still within this component
   * for example in the padded area around the input or the append/prepend adornments.
   */
  const handleClick = React.useCallback(() => {
    if (focused) return;
    ref?.current?.focus();
  }, [ref, focused]);

  /**
   * Track value changes and keep track of the uncontrolled value if needed
   */
  const handleChange = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = ev.target.value;

      if (!controlled) setUnControlledValue(newValue);

      onChange?.(ev);
      onValueChange?.(newValue);
    },
    [controlled, onChange, onValueChange]
  );

  // properties for the inner input element
  const inputProps = {
    value: !controlled ? unControlledValue : value ?? undefined,
    tabIndex: _tabIndex,
    disabled,
    className: cn('input-control p-0 bg-transparent outline-none w-full', {
      [`cursor-not-allowed`]: disabled,
    }),
    onChange: handleChange,
    onFocus: handleFocus,
    onBlur: handleBlur,
    ...otherProps,
    ref: ref,
  };

  return (
    <>
      <div
        onClick={handleClick}
        className={
          variant === UIVariants.unstyled
            ? 'ui-input'
            : cn([
                'ui-input cursor-text',

                UIStyling.focusRing({ ...themableProps, color: 'info' }, focused),
                UIStyling.shapes(themableProps),
                UIStyling.textSizes(themableProps),
                UIStyling.disabled(themableProps),

                // position child elements
                'inline-flex items-center gap-2',
                // add theming info classes
                `variant-${variant} color-${color} size-${size} shape-${shape}`,

                // ----- Variants
                {
                  [`bg-background border border-${color}`]: variant === UIVariants.solid,
                  [`variant-${variant} border border-${color}`]: variant === UIVariants.outline,
                  [`variant-${variant} border border-transparent`]: variant === UIVariants.ghost,
                },
                // ----- Sizes
                {
                  [`size-xs px-1 py-[2px] text-xs`]: size === UISizes.xs,
                  [`size-sm px-1 py-1 text-sm`]: size === UISizes.sm,
                  [`size-md px-1 py-1 text-sm`]: size === UISizes.md,
                  [`size-lg px-2 py-1 text-lg`]: size === UISizes.lg,
                  [`size-xl px-2 py-1 text-xl`]: size === UISizes.xl,
                },

                { [`input-focused`]: focused },
                { [`cursor-not-allowed`]: disabled },

                { [`flex`]: fullWidth },

                theme === 'flowbite' && {
                  [`bg-default-50 border-default-300 text-default-900`]: variant === UIVariants.solid,
                  [`p-2.5`]: size === UISizes.md,
                  [`dark:bg-default-700 dark:border-default-600 dark:placeholder-default-400 dark:text-white`]:
                    variant === UIVariants.solid,
                },
                className,
              ])
        }
      >
        {prepend &&
          renderSlot(
            slots.prepend,
            'ui-input__prepend',
            { children: prepend, ...slotProps?.prepend },
            classNames?.prepend
          )}
        {customInput ? customInput(inputProps) : <input {...inputProps} />}
        {append &&
          renderSlot(slots.append, 'ui-input__append', { children: append, ...slotProps?.append }, classNames?.append)}
      </div>
      {helperText && (
        <div className={cn('text-default-500 dark:text-default-400 mt-2 text-sm', `${classNames?.helperText}`)}>
          {helperText}
        </div>
      )}
    </>
  );
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  InputComp
) as React.ForwardRefExoticComponentExtended<InputProps>;
// Input.displayName = 'Input';
